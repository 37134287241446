import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"

export default function about(props) {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={props.location} title={siteTitle}>
      <main className="markdown">
        <h1 className="text-center font-bold text-3xl">Tools</h1>
        <p className="text-center">What I'm Using to Learn Chinese</p>
        <section>
          {/* <h2>Duolingo</h2>
          <p>
            I'm using Duolingo as my 'main course' for learning Chinese. Why?
            It's easy to use on the go (I practice a lot on my phone) and I can
            pick it up for a quick lesson at any time. I signed up for their
            Black Friday Sale last year and still have a Plus membership, so I
            figured I'd put it to good use.
          </p>
          <p>
            What are the downsides to Duolingo? I think its pronunciation could
            use some work. So far, it's been great at teaching me vocabulary,
            but it's terrible at pronunciation. I think it's important,
            especially at the beginning, to have native pronunciation
            reinforcing your learning.
          </p>
          <p>
            Feel free to send me a friend request - my username is dschla88.
          </p> */}
          <h2>Remembering Simplified Hanzi</h2>
          <p>
            I'm using this book by James Heisig to learn simplified Chinese
            characters. You can buy the book{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.amazon.com/Remembering-Simplified-Hanzi-Meaning-Characters/dp/0824833236"
            >
              here
            </a>{" "}
            (non-affiliate link). I actually would recommend buying books 1 and
            2 on Apple Books as it's cheaper and more convenient.
          </p>
          <p>
            <Link to="">
              Check out my plan to learn 3,000 Chinese characters in 120 days.
            </Link>
          </p>
          <h2>Anki</h2>
          <p>
            I'm using Anki as my spaced repetition software. It's free, open
            source, and you can customize it however you like.
          </p>
          <p>
            I am currently working through{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ankiweb.net/shared/info/1627669267"
            >
              this deck
            </a>
            .
          </p>
          <h2>A Good Microphone</h2>
          <p>
            I use the Audio-Technica ATR2500 to record my audio. You can just as
            easily use your phone and record in a voice memo app.
          </p>
        </section>
        {/* <form
          style={{ maxWidth: `400px` }}
          className="mt-5 flex flex-col mx-auto "
        >
          <input
            style={{ minWidth: `300px` }}
            className="bg-gray-200 placeholder-gray-600 rounded p-2 px-3"
            type="text"
            placeholder="example@example.com"
          ></input>
          <button className="mt-3 p-2 px-8 rounded shadow-xl bg-indigo-800 text-white hover:bg-indigo-600">
            Sign Me Up!
          </button>
        </form> */}
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
